import React from "react";
import { RouteComponentProps } from "react-router";
import Overview from "./Overview.stlye";
import TableWithDataFetch from "./TableWithDataFetch";
import NewsTable from "./NewsTable";
import { useDataApi } from "./hooks";
import { parametrizeQuery } from "./queries";
import {
  get_all_filings_for_case,
  what_is_the_case_overview,
  who_are_the_unsecured_creditors,
  who_is_on_the_committee_of_unsecured_creditors,
  were_previous_cases,
  what_are_the_affiliates_restructured
} from "cq-queries/queries.json";
import { Link } from "react-router-dom";
import format from "./formatUtils";
import _ from "lodash";

type TParams = { itemID: string };
const OVERVIEW_COLUMN_DICT = {
  default: (columnName: string) => ({
    name: columnName,
    label: _.startCase(columnName).toUpperCase()
  }),
  case_number: (columnName: string) => ({
    name: columnName,
    label: "CASE NO.",
    options: {
      customBodyRender: (value: string, tableMeta: { rowData: any[] }) => {
        const path = new URL(tableMeta.rowData[1]).pathname;
        return <Link to={`${path}`}>{value}</Link>;
      }
    }
  }),
  case: (columnName: string) => ({
    name: columnName,
    label: columnName.toUpperCase(),
    options: {
      display: "excluded",
      filter: false
    }
  }),
  docket_number: (columnName: string) => ({
    name: columnName,
    label: "DOCKET NO."
  }),
  pdf: (columnName: string) => ({
    name: columnName,
    label: columnName.toUpperCase(),
    options: {
      display: "excluded",
      filter: false
    }
  }),
  doc_type: (columName: string) => ({
    name: columName,
    label: "DOCUMENT TYPE",
    options: {
      customBodyRender: (value: string, tableMeta: { rowData: any[] }) => {
        const pdfUrl = tableMeta.rowData[3];
        const actionType = tableMeta?.rowData[0];
        type UrlMap = {
          [name: string]: string;
        };
        const URL_MAPPINGS: UrlMap = {
          LegalDocumentFirstDayMotions:
            "Affidavit/Declaration in Support of First Day Motion",
          PlanOfReorganizationFiling: "Chapter 11 Plan of Reorganization",
          VoluntaryPetitionFiling: "Chapter 11 Voluntary Petition",
          LegalDocumentFinalDecree:
            "Final Decree Closing Certain Chapter 11 Cases"
        };

        const mappingKey =
          format.getValueFromUri(value) + format.getValueFromUri(actionType);
        return (
          <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
            {URL_MAPPINGS[mappingKey] || "Filing"}
          </a>
        );
      }
    }
  }),
  legal_act_type: (columName: string) => ({
    name: columName,
    label: "LEGAL ACT TYPE",
    options: {
      display: "excluded",
      filter: false
    }
  }),
  date: (columnName: string) => ({
    name: columnName,
    label: columnName.toUpperCase(),
    options: {
      // TODO: Do a better date parse check since Date.parse() is not 100% safe
      customBodyRender: (value: string) => {
        const date = Date.parse(value)
          ? new Date(value).toLocaleDateString()
          : null;
        return <time style={{ whiteSpace: "nowrap" }}>{date}</time>;
      }
    }
  })
};

const CREDITORS_COLUMNS = {
  default: (columnName: string) => ({
    name: columnName,
    label: columnName.toUpperCase()
  }),
  amount: (columnName: string) => ({
    name: columnName,
    label: columnName.toUpperCase(),
    options: {
      // TODO: Find out why sort is not working properly for numbers
      sort: false,
      customBodyRender: (value: string) => {
        // Create our number formatter.
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD"
        });

        return formatter.format(Number(value));
      }
    }
  })
};

const mockArticles = [
  {
    headline: "PES HOLDINGS: Sets Jan. 10, 2020 Deadline for Offers",
    date: "28 October 2019",
    source: "Troubled Company Reporter",
    img:
      "https://www.inquirer.com/resizer/FLj5JeecTiiADv5uXcEtQrhA4Q4=/600x400/smart/arc-anglerfish-arc2-prod-pmn.s3.amazonaws.com/public/LVYKO6TT75APHIENSUTX2XOJG4.jpg",
    url:
      "https://global.factiva.com/redir/default.aspx?P=sa&an=TRCOREP020191029efas0002u"
  },
  {
    headline:
      "Future owner of PES refinery could be decided as soon as January",
    date: "15 November 2019",
    source: "The Philadelphia Tribune",
    img:
      "https://www.witf.io/wp-content/uploads/2019/07/pes-union-president-1920x1280.jpg",
    url:
      "https://global.factiva.com/redir/default.aspx?P=sa&an=PHLTRB0020191115efbf0002q"
  },
  {
    headline: "PES HOLDINGS: Proposes Dual-Track Chapter 11 Plan",
    date: "21 October 2019",
    source: "Troubled Company Reporter",
    img:
      "https://www.inquirer.com/resizer/Txs2wm7zs5C5kWc3OZ19zZ_HhGg=/600x400/smart/arc-anglerfish-arc2-prod-pmn.s3.amazonaws.com/public/E3MGAHVG2ZDKJMVSFFGLQGF3QM.jpg",
    url:
      "https://global.factiva.com/redir/default.aspx?P=sa&an=TRCOREP020191022efal0002l"
  },
  {
    headline: "PES HOLDINGS: Committee Hires Elliott Greenleaf as Counsel",
    date: "25 September 2019",
    source: "Troubled Company Reporter",
    img:
      "https://s.yimg.com/uu/api/res/1.2/d3iizNCQFLx8hR.ghkNSuw--~B/aD0yOTk7dz00NTA7c209MTthcHBpZD15dGFjaHlvbg--/https://media.zenfs.com/en-US/reuters.com/592ecb43816abd3a4250c95790c708d3",
    url:
      "https://global.factiva.com/redir/default.aspx?P=sa&an=TRCOREP020190926ef9p0002c"
  },
  {
    headline:
      "REFINERY BANKRUPTCY | $4.6M to PES execs after refinery fire; Retention bonuses cameamid bankruptcy planning and massive layoffs with...",
    date: "11 September 2019",
    source: "The Philadelphia Inquirer",
    img:
      "https://s.yimg.com/uu/api/res/1.2/kOVADf_ZH3vzwXCQScQ3.Q--~B/aD0yOTk7dz00NTA7c209MTthcHBpZD15dGFjaHlvbg--/https://media.zenfs.com/en-US/reuters.com/69c96b57c755f5d9a0d46203210e8728",
    url:
      "https://global.factiva.com/redir/default.aspx?P=sa&an=PHLI000020190911ef9b0001e"
  },
  {
    headline:
      "What We Know (So Far) About the Future of the PES Refinery; City and state officialshelped save the refinery from financial doom at least..",
    date: "30 August 2019",
    source: "Philadelphia Magazine",
    img:
      "https://cdn10.phillymag.com/wp-content/uploads/sites/3/2019/08/pes-refinery-featured.jpg",
    url:
      "https://global.factiva.com/redir/default.aspx?P=sa&an=IPHL000020190831ef8u00004"
  }
];

export default function({ match }: RouteComponentProps<TParams>) {
  const query = parametrizeQuery(what_is_the_case_overview, [
    { id: "param_bankruptcy_iri", value: window.location.href, type: "URI" }
  ]);
  const [{ data, isLoading, error }] = useDataApi({
    query
  });

  const dict = data && data.results.bindings[0];

  type GridItem = {
    title: string;
    value: string | null;
    url?: URL;
  };

  function OverviewGridItem(props: GridItem) {
    const { title, value, url } = props;
    return (
      <div key={title}>
        <Overview.GridHeading>{title}</Overview.GridHeading>
        <Overview.GridValue>
          {url ? <Link to={url.pathname}>{value}</Link> : value}
        </Overview.GridValue>
      </div>
    );
  }

  return (
    <>
      {isLoading ? (
        <span>Loading...</span>
      ) : error ? (
        <span>Error: {error.message}</span>
      ) : data && dict ? (
        <Overview.MainContainer>
          <Overview.TitleContainer>
            <div>{dict["company_name"].value}</div>
          </Overview.TitleContainer>
          <Overview.Separator />
          <Overview.GridContainer>
            <div>
              <Overview.GridTitle>Case Overview</Overview.GridTitle>
              <Overview.Details>
                <OverviewGridItem
                  title="Company Name"
                  value={dict["company_name"]?.value}
                />
                <OverviewGridItem
                  title="Case Number"
                  value={dict["case_number"]?.value}
                />
                <OverviewGridItem
                  title="Filed At Date"
                  value={
                    Date.parse(dict["filed_at_date"]?.value)
                      ? new Date(
                          dict["filed_at_date"]?.value
                        ).toLocaleDateString()
                      : null
                  }
                />
                <OverviewGridItem
                  title="Court Name"
                  value={dict["court_name"]?.value}
                />
                <OverviewGridItem
                  title="Judge"
                  value={dict["judge_name"].value}
                  url={new URL(dict["judge"].value)}
                />
                <OverviewGridItem
                  title="Status"
                  value={dict["is_closed"] ? "Closed" : "Active"}
                />
              </Overview.Details>
              <Overview.Separator />
              <Overview.GridTitle>Previous Cases</Overview.GridTitle>
              <TableWithDataFetch
                query={parametrizeQuery(were_previous_cases, [
                  {
                    id: "param_bankruptcy_iri",
                    value: window.location.href,
                    type: "URI"
                  }
                ])}
                columnDict={OVERVIEW_COLUMN_DICT}
                reasoning
              />
            </div>

            <Overview.FlexCell>
              <Overview.GridTitle>News</Overview.GridTitle>
              <NewsTable articles={mockArticles} />
            </Overview.FlexCell>
          </Overview.GridContainer>
          <Overview.Separator />
          <Overview.GridContainer>
            <Overview.FlexCell>
              <Overview.GridTitle>
                Committee of Unsecured Creditors
              </Overview.GridTitle>
              <TableWithDataFetch
                query={parametrizeQuery(
                  who_is_on_the_committee_of_unsecured_creditors,
                  [
                    {
                      id: "param_bankruptcy_iri",
                      value: window.location.href,
                      type: "URI"
                    }
                  ]
                )}
                reasoning={true}
                columnDict={{
                  default: OVERVIEW_COLUMN_DICT["default"],
                  full_address: (columnName: string) => ({
                    name: columnName,
                    label: "ADDRESS"
                  })
                }}
              />
            </Overview.FlexCell>

            <Overview.FlexCell>
              <Overview.GridTitle>Creditors (Unsecured)</Overview.GridTitle>
              <TableWithDataFetch
                query={parametrizeQuery(who_are_the_unsecured_creditors, [
                  {
                    id: "param_bankruptcy_iri",
                    value: window.location.href,
                    type: "URI"
                  }
                ])}
                reasoning={true}
                columnDict={CREDITORS_COLUMNS}
              />
            </Overview.FlexCell>
          </Overview.GridContainer>
          <Overview.Separator />
          <Overview.GridContainer>
            <Overview.FlexCell>
              <Overview.GridTitle>Court Filings</Overview.GridTitle>
              <TableWithDataFetch
                query={parametrizeQuery(get_all_filings_for_case, [
                  {
                    id: "param_bankruptcy_iri",
                    value: window.location.href,
                    type: "URI"
                  }
                ])}
                reasoning={true}
                columnDict={OVERVIEW_COLUMN_DICT}
                options={{ search: true }}
              />
            </Overview.FlexCell>

            <Overview.FlexCell>
              <Overview.GridTitle>
                Corporate Affiliates - Joint Administration
              </Overview.GridTitle>
              <TableWithDataFetch
                query={parametrizeQuery(what_are_the_affiliates_restructured, [
                  {
                    id: "param_bankruptcy_iri",
                    value: window.location.href,
                    type: "URI"
                  }
                ])}
                reasoning={true}
                columnDict={{
                  default: OVERVIEW_COLUMN_DICT["default"],
                  case_number: (columnName: string) => ({
                    name: columnName,
                    label: "CASE NO."
                  })
                }}
              />
            </Overview.FlexCell>
          </Overview.GridContainer>
        </Overview.MainContainer>
      ) : (
        <span>
          There were some problems fetching this case. Please try again later
        </span>
      )}
    </>
  );
}
